$primary-colour: #556e84;
$secondary-colour: #ffffff;
$accent-colour: #556e84;
$background-colour: #f3f3f3;


@font-face {
  font-family: "lato regular";
  src: url("../src/fonts/Lato Regular.ttf") format("truetype");
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: x-large;
  font-family: "lato regular", CerebriSans-Regular, -apple-system, system-ui, Roboto, sans-serif;
  color: $accent-colour;
  overflow-y: auto;
  background-color: $background-colour;
}

.header {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.xx-large-font {
  font-size: xx-large;
}

.small-font {
  font-size: small;
}

.primary-colour-font {
  color: $primary-colour;
}

.secondary-colour-font {
  color: $secondary-colour;
}

.accent-colour-font{
  color: $accent-colour;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.full-width {
  width: 100%
}

.full-height {
  height: 100%;
}

.header-link {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
  margin-left: 15px;
}

.outlet {
  height: 80vh;
}

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
}

.breathe-content {
  position: relative;
  color: $secondary-colour;
  background-color: $primary-colour;
  height: 50vmin;
  width: 50vmin;
  max-height: 150px;
  max-width: 150px;
  border-radius: 8px;
}

.sub-breathe-content {
  height: 10vh;
}

.fade-in {
  animation: fadeIn 2s linear;
}

.share {
  height: 100%;
}

.social-share {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  height: auto;
  width: 100%;
  margin-top: 2rem;
}

.share-detail {
  font-size: x-large;
}

.share-your-progress:hover,
.share-your-progress:active {
  font-size: 50px;
}

.header-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  height: 100%;
  padding-left: 5%;
  font-size: large;
  font-weight: bold;
  color: $primary-colour;
  cursor: pointer;
}

.header-text {
  margin-left: 5%;
}

.header-item-container:hover,
.header-item-container:active {
  color: $secondary-colour;
  background-color: $primary-colour;
}

.overlay {
  position: fixed;
  top: 41px;
  right: 15px;
  border-radius: 25px 5px;
  height: 0;
  width: 50%;
  max-width: 150px;
  max-height: 150px;
  z-index: 1;
  background-color: $secondary-colour;
  overflow-y: auto;
  transition: 0.2s;
}

.overlay-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.open-overlay {
  font-size: xx-large;
  cursor: pointer;
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 2;
}

.settings {
  height: 100%;
}

.second-counts {
  height: auto;
  width: 100%;
}

.settings-instruction {
  font-size: x-large;
  font-weight: bold;
  justify-content: left;
}

.restore-default-settings {
  font-size: small;
}

.settings-lines {
  display: grid;
  grid-template-columns: 50vmin 50vmin;
  justify-content: center;
}

.setting-value {
  margin-top: 0.3rem;
}

.setting-line {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: x-large;
}

.second-control {
  height: 30px;
  width: 30px;
  font-size: x-large;
  font-weight: bold;
  border: 2px solid $accent-colour;
  border-radius: 50%;
  line-height: 50%;
  color: $secondary-colour;
  background-color: $accent-colour;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.second-control:active,
.second-control:hover {
  cursor: pointer;
}

.plus-second-control {
  margin-left: 1rem;
}

.minus-second-control {
  margin-right: 1rem;
}

hr {
  background-color: $secondary-colour;
  border: 0;
  color: $secondary-colour;
  height: 1px;
  width: 100px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.hr-primary {
  background-color: $background-colour;
  border: 0;
  color: $background-colour;
  height: 0.8px;
  width: 80%;
  margin: 0;
}


.about {
  height: 100%;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-detail {
  font-size: large;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.box-breathing-container {
  margin: 0 auto;
  width: 60vmin;
  height: 60vmin;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 25% 50% 25%;
}

.box-breathing {
  background-color: $primary-colour;
}

.box-breathing-animation {
  position: relative;
  animation-name: square;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  height: 8px;
  width: 8px;
  display: flex;
  border-radius: 50%;
  background-color: $secondary-colour;
}

.box-breathing-side {
  display: flex;
  align-items: center;
  justify-content: center;
}

.breathe-dot{
  position: absolute;
  top: -4px;
  left: -4px;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}


@keyframes square {
  0% {
    top: -4px;
    left: -4px;
  }

  25% {
    top: -4px;
    left: calc(100% - 4px);
  }

  50% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }

  75% {
    top: calc(100% - 4px);
    left: -4px;
  }

  100% {
    top: -4px;
    left: -4px;
  }
}

@-moz-keyframes square {
  0% {
    top: -4px;
    left: -4px;
  }

  25% {
    top: -4px;
    left: calc(100% - 4px);
  }

  50% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }

  75% {
    top: calc(100% - 4px);
    left: -4px;
  }

  100% {
    top: -4px;
    left: -4px;
  }
}

@-webkit-keyframes square {
  0% {
    top: -4px;
    left: -4px;
  }

  25% {
    top: -4px;
    left: calc(100% - 4px);
  }

  50% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }

  75% {
    top: calc(100% - 4px);
    left: -4px;
  }

  100% {
    top: -4px;
    left: -4px;
  }
}

@keyframes breathe-0 {
  0% {
    top: -4px;
    left: -4px;
  }

  100% {
    top: -4px;
    left: calc(100% - 4px);
  }
}

@keyframes breathe-1 {
  0% {
    top: -4px;
    left: calc(100% - 4px);
  }

  100% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }
}

@keyframes breathe-2 {
  0% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }

  100% {
    top: calc(100% - 4px);
    left: -4px;
  }
}

@keyframes breathe-3 {
  0% {
    top: calc(100% - 4px);
    left: -4px;
  }

  100% {
    top: -4px;
    left: -4px;
  }
}

@-moz-keyframes breathe-0 {
  0% {
    top: -4px;
    left: -4px;
  }

  100% {
    top: -4px;
    left: calc(100% - 4px);
  }
}

@-moz-keyframes breathe-1 {
  0% {
    top: -4px;
    left: calc(100% - 4px);
  }

  100% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }
}

@-moz-keyframes breathe-2 {
  0% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }

  100% {
    top: calc(100% - 4px);
    left: -4px;
  }
}

@-moz-keyframes breathe-3 {
  0% {
    top: calc(100% - 4px);
    left: -4px;
  }

  100% {
    top: -4px;
    left: -4px;
  }
}

@-webkit-keyframes breathe-0 {
  0% {
    top: -4px;
    left: -4px;
  }

  100% {
    top: -4px;
    left: calc(100% - 4px);
  }
}

@-webkit-keyframes breathe-1 {
  0% {
    top: -4px;
    left: calc(100% - 4px);
  }

  100% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }
}

@-webkit-keyframes breathe-2 {
  0% {
    top: calc(100% - 4px);
    left: calc(100% - 4px);
  }

  100% {
    top: calc(100% - 4px);
    left: -4px;
  }
}

@-webkit-keyframes breathe-3 {
  0% {
    top: calc(100% - 4px);
    left: -4px;
  }

  100% {
    top: -4px;
    left: -4px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.progress-container {
  display: flex;
  align-items: center;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
  background: $background-colour;
  width: 50vmin;
  max-width: 150px;
  height: 4vh;
  border-radius: 8px;
}

.progress-moved {
  height: 100%;
  animation: progressAnimation 120s;
  border-radius: 8px;
}

@keyframes progressAnimation {
  0% {
    width: 3%;
    background-color: $primary-colour;
  }

  100% {
    width: 100%;
    background-color: $primary-colour;
  }
}